import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { chatbotUserDataGetChatbotUserDataAction } from "./action";
export const getChatbotUserDataRequest = function* (
  action: ReturnType<typeof chatbotUserDataGetChatbotUserDataAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbotUserData.methods.getChatbotUserData(...action.payload)
    );
    yield put(
      chatbotUserDataGetChatbotUserDataAction.success(
        response.data,
        action.meta
      )
    );
  } catch (error) {
    yield put(
      chatbotUserDataGetChatbotUserDataAction.failure(error, action.meta)
    );
  }
};

export const chatbotUserDataSaga = function* () {
  yield all([
    takeEvery(
      chatbotUserDataGetChatbotUserDataAction.request,
      getChatbotUserDataRequest
    ),
  ]);
};
