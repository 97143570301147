import { userReducer } from "./user/reducer";
import { combineReducers } from "redux";
import { StateType } from "typesafe-actions";
import { tokenReducer } from "./token/reducer";
import { phoneAuthReducer } from "./phoneAuth/reducer";
import { chatbotReducer } from "./chatbot/reducer";
import { chatbotListReducer } from "./chatbotList/reducer";
import { mapPlacesReducer } from "./mapPlaces/reducer";
import { mapSearchPlacesReducer } from "./mapSearchPlaces/reducer";
import { placelistsReducer } from "./placelists/reducer";
import { placelistReducer } from "./placelist/reducer";
import { bookingPlacesReducer } from "./bookingPlaces/reducer";
import { bookingPlaceDataReducer } from "./placeBookingData/reducer";
import { bugReportReducer } from "./bugReport/reducer";
import { placeReducer } from "./place/reducer";
import { chatbotUserDataReducer } from "./chatbotUserData/reducer";
import { chatbotFeedbackReducer } from "./chatbotFeedback/reducer";

const reducers = {
  user: userReducer,
  token: tokenReducer,
  phoneAuth: phoneAuthReducer,
  chatbot: chatbotReducer,
  chatbotList: chatbotListReducer,
  mapPlaces: mapPlacesReducer,
  mapSearchPlaces: mapSearchPlacesReducer,
  placelists: placelistsReducer,
  placelist: placelistReducer,
  bookingPlaces: bookingPlacesReducer,
  bookingPlaceData: bookingPlaceDataReducer,
  bugReport: bugReportReducer,
  place: placeReducer,
  chatbotUserData: chatbotUserDataReducer,
  chatbotFeedback: chatbotFeedbackReducer,
};

const rootReducer = combineReducers(reducers);
export default rootReducer;
export type RootState = StateType<typeof reducers>;
