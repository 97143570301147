import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ActivityIndicator,
  Keyboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { MapSidePanel, SearchPlaceList } from "../components/map/MapSidePanel";
import { useIsMobile } from "./ChatPage";
import { MobileHeader } from "../components/MobileHeader";
import { colors } from "@gadder/common/src/styles/colors";
import { PlacelistList } from "../components/placelist/placelistPanel";
import {
  defaultPlaceFilter,
  PlaceSearchFiltersType,
} from "@gadder/common/src/components/placeSearch/types/filters";
import { filterMeilisearchImplementation } from "@gadder/common/src/components/placeSearch/utils/filterMeilisearch";
import { DrawerWrapper } from "../components/drawerWrapper";
import { SideMenu, SideMenuTabs } from "../components/chat/sideMenu";
import { CommonInput } from "../components/inputs";
import { ReactComponent as FilterIcon } from "@gadder/common/src/assets/svg/filter_new_light.svg";
import { ReactComponent as FilterBlackIcon } from "@gadder/common/src/assets/svg/filter_new_black.svg";
import { ReactComponent as MapIcon } from "@gadder/common/src/assets/svg/map_pin_big_black.svg";
import { ReactComponent as GridIcon } from "@gadder/common/src/assets/svg/grid_black.svg";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../store/root-reducer";
import {
  mapSearchPlacesClearAllBookingDataAction,
  mapSearchPlacesGetAction,
} from "../store/mapSearchPlaces/action";
import { useDebounce } from "@gadder/common/src/utils/use-debounce";
import {
  FiltersMenu,
  isFiltersSelectedHandler,
} from "../components/map/FiltersMenu";
import { MapWithPlaces } from "../components/map/MapWithPlaces";
import { FullPlaceType } from "@gadder/common/src/api/custom-models/place";
import { ReactComponent as SearchBlack } from "@gadder/common/src/assets/svg/search_map.svg";
import { PlaceCardSmall } from "../components/PlaceCardSmall";
import { CommonButton } from "@gadder/common/src/components/buttons";
import {
  bookingSearchAction,
  bookingSearchSubscribeAction,
  bookingSearchUnsubscribeAction,
} from "../store/bookingPlaces/action";
import { fonts } from "@gadder/common/src/styles/fonts";
import { useDispatch } from "react-redux";
import { placeBookingDataGetBookingDataAction } from "../store/placeBookingData/action";
import { useLocation, useSearchParams } from "react-router-dom";
import { InputSelect } from "../components/InputSelect";
import { DatePicker } from "../components/DatePicker";
import { format } from "date-fns";
import { BugButton } from "../components/BugButton";
import { WhatsappButton } from "../components/WhatsappButton";

export const MapPage = () => {
  const isMobile = useIsMobile();

  const [params] = useSearchParams();

  const { state } = useLocation();

  const initSearchParams = useMemo(() => state?.searchParams, [state]);

  const [placeIdToSelect, setPlaceIdToSelect] = useState<string | null>(null);

  useEffect(() => {
    const placeId = params.get("placeId");
    if (placeId) {
      setPlaceIdToSelect(placeId);
    }
  }, [params]);

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const [filters, setFilters] =
    useState<PlaceSearchFiltersType>(defaultPlaceFilter);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (initSearchParams) {
      setSearchValue(initSearchParams.searchValue);
      setFilters((prevState) => ({
        ...prevState,
        bookingMode: true,
        bookingFilters: {
          date: initSearchParams.date,
          personsCount: initSearchParams.personsCount,
        },
      }));
    }
  }, [initSearchParams]);

  const searchPlaceFilters = useMemo(
    () => ({
      searchValue,
      filter: filterMeilisearchImplementation(filters),
      fullResult: true,
      bookingConfig: filters.bookingMode ? filters.bookingFilters : null,
    }),
    [searchValue, filters]
  );

  useEffect(() => {
    if (
      initSearchParams &&
      !isBookingSearched &&
      searchPlaceFilters.bookingConfig
    ) {
      bookingSearchHandler();
      setIsBookingSearched(true);
    }
  }, [searchPlaceFilters]);

  const [mobileMapOpen, setMobileMapOpen] = useState(false);

  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState(false);

  const searchPlacesState = useSelector(
    (state: RootState) => state.mapSearchPlaces
  );

  const [loadSearchPlacesToken, loadSearchPlaces] = useUniqueDispatch(
    mapSearchPlacesGetAction.request
  );

  const isPlacesLoading = isLoading(searchPlacesState, loadSearchPlacesToken);

  const searchThrottleHandle = useDebounce(
    (params: any) => loadSearchPlaces(params),
    800
  );

  const isFiltersSelected = useMemo(
    () => isFiltersSelectedHandler(filters),
    [filters]
  );

  const [selectedPlace, setSelectedPlace] = useState<FullPlaceType | null>(
    null
  );

  const placeRoute = (place_id: string, booking_dates: string[] = []) => {
    window.open("/place/" + place_id, "_blank");
  };

  const [bookingSubscribeToken, bookingSubscribe] = useUniqueDispatch(
    bookingSearchSubscribeAction.request
  );

  const [bookingUnsubscribeToken, bookingUnsubscribe] = useUniqueDispatch(
    bookingSearchUnsubscribeAction.request
  );

  const [bookingSearchToken, bookingSearch] = useUniqueDispatch(
    bookingSearchAction.request
  );

  useEffect(() => {
    bookingSubscribe([]);

    return () => {
      bookingUnsubscribe([]);
    };
  }, []);

  const bookingSearchHandler = () => {
    const date = searchPlaceFilters.bookingConfig.date;
    bookingSearch([
      {
        ...searchPlaceFilters,
        bookingConfig: {
          ...searchPlaceFilters.bookingConfig,
          date: format(date, "Y-MM-dd"),
          time: format(date, "HH:mm"),
        },
      },
    ]);
  };

  useEffect(() => {
    !filters.bookingMode && placeSearchHandle();
  }, []);

  const bookingPlacesData = useSelector(
    (store: RootState) => store.bookingPlaces.data
  );

  const searchPlaces = useMemo(
    () =>
      filters.bookingMode
        ? bookingPlacesData.places
        : searchPlacesState?.data?.data || [],
    [searchPlacesState?.data?.data, bookingPlacesData, filters.bookingMode]
  );

  const searchPlaceIds = useMemo(
    () =>
      !searchPlaces && !filters.bookingMode
        ? null
        : !isFiltersSelected && !filters.bookingMode
        ? null
        : searchPlaces.map((_place) => _place.place_id),
    [searchPlaces, isFiltersSelected, filters.bookingMode]
  );

  const mapPinPlaces = useSelector((state: RootState) => state.mapPlaces.data);

  useEffect(() => {
    if (placeIdToSelect && mapPinPlaces) {
      const place = mapPinPlaces?.find(
        (_place) => _place.place_id === placeIdToSelect
      );
      if (place) {
        setSelectedPlace(place);
        setPlaceIdToSelect(null);
      }
    }
  }, [placeIdToSelect, mapPinPlaces]);

  const [isBookingSearched, setIsBookingSearched] = useState(false);

  const placeSearchHandle = useCallback(() => {
    if (filters.bookingMode) {
      bookingSearchHandler();
      !isBookingSearched && setIsBookingSearched(true);
    } else {
      searchThrottleHandle([searchPlaceFilters]);
    }
  }, [searchPlaceFilters, filters, isBookingSearched]);

  const [clearBookingDataToken, clearBookingData] = useUniqueDispatch(
    mapSearchPlacesClearAllBookingDataAction
  );

  useEffect(() => {
    clearBookingData([]);
  }, [filters.bookingFilters]);

  useEffect(() => {
    if (selectedPlace) {
      setSelectedPlace(
        searchPlaces?.find(
          (_place) => _place.place_id === selectedPlace.place_id
        ) ||
          mapPinPlaces?.find(
            (_place) => _place.place_id === selectedPlace.place_id
          ) ||
          null
      );
    }
  }, [searchPlaces, mapPinPlaces]);

  return (
    <View
      style={{
        flex: 1,
        flexDirection: isMobile ? "column" : "row",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {!isMobile && !isFiltersMenuOpen && (
        <MapSidePanel
          bookingMode={filters.bookingMode}
          showSearchButton={filters.bookingMode && searchPlaces.length === 0}
          onSearchButtonPressed={placeSearchHandle}
          onFilterChange={setFilters}
          selectedFilters={filters}
          onSearchPress={placeSearchHandle}
          isFiltersSelected={isFiltersSelected}
          onFilterPress={() => setIsFiltersMenuOpen(true)}
          isLoading={
            isPlacesLoading ||
            (filters.bookingMode &&
              searchPlaces.length === 0 &&
              bookingPlacesData.isPlacesLoading)
          }
          isNextLoading={bookingPlacesData.isPlacesLoading}
          places={searchPlaces}
          searchValue={searchValue}
          onSearchValueChange={setSearchValue}
          onPlaceSelect={setSelectedPlace}
        />
      )}

      {isFiltersMenuOpen && (
        <View
          style={
            isMobile ? styles.filterMobileContainer : styles.filterContainer
          }
        >
          <FiltersMenu
            onSearch={placeSearchHandle}
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
            selectedFilters={filters}
            onFilterChange={setFilters}
            onBack={() => {
              setIsFiltersMenuOpen(false);
            }}
          />
        </View>
      )}

      {isMobile && (
        <View style={{ position: "relative" }}>
          <MobileHeader onBurgerPress={() => setIsMobileDrawerOpen(true)} />
        </View>
      )}

      {isMobile && (
        <View
          style={[
            styles.placeListMobileContainer,
            { top: mobileMapOpen || isFiltersMenuOpen ? "100%" : 0 },
          ]}
        >
          <View style={styles.mobileSearchListWrapper}>
            <SearchPlaceList
              bookingMode={filters.bookingMode}
              ListHeaderComponent={
                <View style={{ paddingTop: 15 }}>
                  <MobileSearchBar
                    onSearch={placeSearchHandle}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    isFiltersSelected={isFiltersSelected}
                    mobileMapOpen={mobileMapOpen}
                    onFiltersButtonPress={() => setIsFiltersMenuOpen(true)}
                    onMapButtonPress={() => setMobileMapOpen(true)}
                  />
                  <View
                    style={{ flexDirection: "row", height: 47, marginTop: 12 }}
                  >
                    <InputSelect
                      iconContainerStyle={{ top: 11 }}
                      value={filters.bookingFilters?.personsCount}
                      // isActive={selectedFilters.bookingMode}
                      style={{ marginRight: 8, height: "100%" }}
                      onChange={(_count) =>
                        setFilters((prevState) => ({
                          ...prevState,
                          bookingFilters: {
                            ...prevState.bookingFilters,
                            personsCount: _count,
                          },
                        }))
                      }
                    />

                    <DatePicker
                      dateButtonStyle={{
                        paddingVertical: "unset",
                        height: "100%",
                        justifyContent: "center",
                      }}
                      style={{ flex: 1, alignSelf: "stretch" }}
                      // isActive={selectedFilters.bookingMode}
                      date={filters.bookingFilters?.date}
                      calendarStyle={{ left: -24 }}
                      setDate={(_date) =>
                        setFilters((prevState) => ({
                          ...prevState,
                          bookingFilters: {
                            ...prevState.bookingFilters,
                            date: _date,
                          },
                        }))
                      }
                    />
                  </View>
                </View>
              }
              bookingDataParams={
                !filters.bookingMode ? filters.bookingFilters : undefined
              }
              onSearchButtonPressed={placeSearchHandle}
              showSearchButton={filters.bookingMode && !isBookingSearched}
              onPlaceSelect={(place) => {
                placeRoute(place.place_id, place.booking_dates);
              }}
              onAddressPress={(place) => {
                setSelectedPlace(place);
                setMobileMapOpen(true);
              }}
              isNextLoading={bookingPlacesData.isPlacesLoading}
              isLoading={
                isPlacesLoading ||
                (filters.bookingMode &&
                  searchPlaces.length === 0 &&
                  bookingPlacesData.isPlacesLoading)
              }
              places={searchPlaces}
              contentContainerStyle={{
                gap: 12,
                paddingHorizontal: 16,
                paddingBottom: 32,
              }}
            />
          </View>
        </View>
      )}

      <MapWithPlaces
        isPlacesLoading={bookingPlacesData.isPlacesLoading}
        onPlaceSelect={(place_id) => {
          setSelectedPlace(
            searchPlaces?.find((_place) => _place.place_id === place_id) ||
              mapPinPlaces?.find((_place) => _place.place_id === place_id) ||
              null
          );
        }}
        selectedPlace={selectedPlace}
        searchIds={searchPlaceIds}
        filters={filters}
      />

      {isMobile && mobileMapOpen && (
        <View style={{ position: "absolute", top: 69, left: 16, right: 16 }}>
          <MobileSearchBar
            onSearch={placeSearchHandle}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isFiltersSelected={isFiltersSelected}
            mobileMapOpen={mobileMapOpen}
            onFiltersButtonPress={() => setIsFiltersMenuOpen(true)}
            onMapButtonPress={() => setMobileMapOpen(false)}
          />
        </View>
      )}

      {isMobile && mobileMapOpen && selectedPlace && (
        <View style={[styles.selectedPlaceMobileContainer]}>
          <View style={[styles.mobileBottomPlaceContainer]}>
            <PlaceCardSmall
              onPress={() =>
                placeRoute(selectedPlace.place_id, selectedPlace.booking_dates)
              }
              mapMod
              bookingDataParams={
                !filters.bookingMode ? filters.bookingFilters : undefined
              }
              place={selectedPlace}
            />
          </View>
        </View>
      )}

      {isMobile && (
        <BugButton
          label={"Bug"}
          style={{ position: "absolute", left: 26, bottom: 30, zIndex: 1 }}
        />
      )}

      {isMobile && (
        <WhatsappButton
          style={{ position: "absolute", right: 26, bottom: 30, zIndex: 1 }}
        />
      )}

      {isMobile && (
        <DrawerWrapper
          onClose={() => setIsMobileDrawerOpen(false)}
          isOpen={isMobileDrawerOpen}
        >
          <SideMenu selectedTab={SideMenuTabs.MAP} />
        </DrawerWrapper>
      )}
    </View>
  );
};

const MobileSearchBar = ({
  searchValue,
  onSearch,
  setSearchValue,
  isFiltersSelected,
  onMapButtonPress,
  mobileMapOpen,
  onFiltersButtonPress,
}: {
  searchValue: string;
  onSearch: () => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  isFiltersSelected: boolean;
  mobileMapOpen: boolean;
  onMapButtonPress: () => void;
  onFiltersButtonPress: () => void;
}) => {
  return (
    <View style={styles.mobileSearchBar}>
      <View style={styles.searchBar}>
        <CommonInput
          textInputStyle={{ padding: 12 }}
          style={{
            backgroundColor: colors.white,
            flex: 1,
            borderWidth: 0,
            borderRadius: 0,
          }}
          onKeyPress={(event) => {
            if (
              (event.nativeEvent.key === "Enter" ||
                event.nativeEvent.key === "NumpadEnter") &&
              searchValue
            ) {
              event.preventDefault();
              onSearch();
              Keyboard.dismiss();
            }
          }}
          placeholder={"Search by name ..."}
          returnKeyType={"search"}
          value={searchValue}
          onChangeText={setSearchValue}
        />
        <TouchableOpacity onPress={onSearch} style={[styles.searchButton]}>
          {<SearchBlack />}
        </TouchableOpacity>
      </View>

      <View style={styles.topSearchButtonContainer}>
        <TouchableOpacity
          style={[
            styles.topSearchButton,
            styles.topSearchButton_left,
            isFiltersSelected && { backgroundColor: colors.black },
          ]}
          onPress={onFiltersButtonPress}
        >
          <View style={{ height: 22 }}>
            {isFiltersSelected ? <FilterIcon /> : <FilterBlackIcon />}
          </View>
          {/*<Text*/}
          {/*  style={[*/}
          {/*    fonts.smallMediumText_medium,*/}
          {/*    { color: isFiltersSelected ? colors.white : colors.black },*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  Filter*/}
          {/*</Text>*/}
          <Text
            style={[
              fonts.smallText_medium,
              { color: isFiltersSelected ? colors.white : colors.black },
            ]}
          >
            Filter
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.topSearchButton, styles.topSearchButton_right]}
          onPress={onMapButtonPress}
        >
          <View style={{ height: 22 }}>
            {mobileMapOpen ? <GridIcon /> : <MapIcon />}
          </View>

          <Text style={[fonts.smallText_medium, { color: colors.black }]}>
            {mobileMapOpen ? "Places" : "Map"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mobileSearchBar: {
    flexDirection: "row",
  },
  topSearchButtonContainer: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: colors.gray81,
    borderRadius: 10,
    overflow: "hidden",
    marginLeft: 8,
  },
  topSearchButton: {
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
  topSearchButton_left: {
    borderRightWidth: 1,
    borderRightColor: colors.gray81,
    backgroundColor: colors.white,
  },
  topSearchButton_right: {
    backgroundColor: colors.white,
  },
  searchBar: {
    flexDirection: "row",
    marginRight: 8,
    borderWidth: 1,
    borderColor: colors.gray81,
    borderRadius: 10,
    overflow: "hidden",
    flex: 1,
  },
  searchButton: {
    paddingHorizontal: 12,
    paddingVertical: 7,
    backgroundColor: colors.white,
    borderLeftWidth: 1,
    borderColor: colors.gray81,
  },
  mobileSearchListWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.white,
  },
  mobileBottomPlaceContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,

    elevation: 1,
    shadowOffset: {
      width: 0,
      height: -0.5,
    },
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowRadius: 0,
  },
  filterMobileContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 2,
  },
  filterContainer: {
    height: "100%",
    width: "33%",
    minWidth: 570,
  },
  placeListMobileContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 1,
    marginTop: 54,
  },
  selectedPlaceMobileContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});
