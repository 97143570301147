import React from "react";
import { Text, View } from "react-native";
import { useNavigate } from "react-router-dom";
import { CommonScreenCard } from "../components/CommonScreenCard";
import { Header } from "../components/Header";
import { ReactComponent as MascotSubscribeStarter } from "@gadder/common/src/assets/svg/gadder_moscot.svg";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { useIsMobile } from "./ChatPage";

export const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  return (
    <CommonScreenCard
      style={{ flex: 1 }}
      screenStyle={isMobile ? { justifyContent: "unset" } : {}}
    >
      <Header
        onPressLeft={() => {
          navigate("/");
        }}
      />
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Text
          style={[
            fonts.title,
            { marginBottom: 16, color: colors.black, textAlign: "center" },
          ]}
        >
          Thank you for subscribing.
        </Text>
        <Text
          style={[
            fonts.mediumText_regular,
            {
              marginBottom: 16,
              color: colors.noteGray,
              paddingHorizontal: 32,
              textAlign: "center",
            },
          ]}
        >
          You can now continue using WhatsApp bot or log in below to use our web
          app
        </Text>
        <View style={{ flexDirection: "column" }}>
          <CommonButton
            textStyle={fonts.mediumText_bold}
            title="Login"
            type="blackFilled"
            containerStyle={{ width: 170, marginBottom: 12 }}
            onPress={() => navigate("../phone")}
          />
          <CommonButton
            textStyle={fonts.mediumText_bold}
            title="Registration"
            type="black"
            containerStyle={{ width: 170 }}
            onPress={() => navigate("../phone")}
          />
        </View>
      </View>
    </CommonScreenCard>
  );
};
