import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";
import { createAction, createAsyncAction } from "typesafe-actions";

export const chatbotPostMessageAction = createAsyncActionWithMeta(
  "chatbot_chatbotPostMessageAction_REQUEST",
  "chatbot_chatbotPostMessageAction_SUCCESS",
  "chatbot_chatbotPostMessageAction_FAILURE"
)<
  Parameters<typeof api.chatbot.methods.postMessage>,
  typeof api.chatbot.type,
  Error
>();

export const chatbotGetChatAction = createAsyncActionWithMeta(
  "chatbot_chatbotGetChatAction_REQUEST",
  "chatbot_chatbotGetChatAction_SUCCESS",
  "chatbot_chatbotGetChatAction_FAILURE"
)<
  Parameters<typeof api.chatbot.methods.getChat>,
  typeof api.chatbot.type,
  Error
>();

export const chatbotSubscribeAction = createAsyncAction(
  "chatbotSubscribe_REQUEST",
  "chatbotSubscribe_SUCCESS",
  "chatbotSubscribe_FAILURE"
)<{ userId: string }, any, Error>();

export const chatbotUnsubscribeAction = createAsyncAction(
  "chatbotUnsubscribe_REQUEST",
  "chatbotUnsubscribe_SUCCESS",
  "chatbotUnsubscribe_FAILURE"
)<void, any, Error>();

export const chatbotPostMessageEventAction = createAsyncAction(
  "chatbotPostMessageEvent_REQUEST",
  "chatbotPostMessageEvent_SUCCESS",
  "chatbotPostMessageEvent_FAILURE"
)<void, any, Error>();

export const chatbotHideErrorAction = createAction(
  "chatbotHideErrorAction"
)<any>();

export const chatbotPostEventAction = createAsyncActionWithMeta(
  "chatbot_chatbotPostEventAction_REQUEST",
  "chatbot_chatbotPostEventAction_SUCCESS",
  "chatbot_chatbotPostEventAction_FAILURE"
)<
  Parameters<typeof api.chatbot.methods.postEvent>,
  typeof api.chatbot.type,
  Error
>();
