import { getToken, setToken } from "../api/storage";
import { api } from "../api";
import { AxiosRequestConfig } from "axios";

const storeApi = {
  user: {
    methods: {
      resetPassword: api.user.postApiAuthResetPassword,
      sendConfirmEmail: api.user.postApiUsersMeSendConfirmEmail,
      checkEmail: api.user.postApiUsersCheckEmail,
      login: api.user.postUsersMeEmailLogin,
      getMe: api.user.getApiUsersMe,
      updateMe: api.user.postApiUsersMe,
      deleteMe: api.user.postApiUsersDeleteMe,
      forgotPassword: api.user.postApiForgotPassword,
      register: async (
        data,
        options: AxiosRequestConfig,
        provider = "phone"
      ) => {
        let resp;
        resp = await api.authExt.postApiAuthExtAuthProviderAuthStepStep(
          provider,
          "0",
          data,
          {}
        );
        return resp;
      },
    },
  },
  token: {
    methods: {
      setToken: setToken,
      getToken: getToken,
    },
  },

  phoneAuth: {
    methods: {
      /*CRUD.create */
      postSms: api.phoneAuth.postApiPhoneAuthSms,
    },
    type: {} as unknown as any,
  },
  chatbot: {
    methods: {
      postMessage: api.chatbot.postMessage,
      getChat: api.chatbot.getChatbotChatId,
      postEvent: api.chatbot.postApiChatbotSaveSessionEvent,
    },
    type: {} as unknown as any,
  },
  chatbotUserData: {
    methods: {
      getChatbotUserData: api.chatbot.getChatbotUserPhoneDataUserId,
    },
    type: {} as unknown as any,
  },
  chatbotList: {
    methods: {
      getChatList: api.chatbot.getChatbotChat,
      postChat: api.chatbot.postChatbotChat,
      updateChat: api.chatbot.putChatbotChatId,
      deleteChat: api.chatbot.deleteChatbotChatId,
    },
    type: {} as unknown as any,
  },
  chatbotFeedback: {
    methods: {
      postFeedback: api.chatbot.postApiChatbotPostChatbotFeedback,
    },
    type: {} as unknown as any,
  },
  pinPlaces: {
    methods: {
      get: api.places.getAllPlacesPins,
    },
  },
  mapSearchPlaces: {
    methods: {
      get: api.place.getApiPlace,
      getBookingData: api.place.getBookingData,
    },
  },
  advancedSearchPlaces: {
    methods: {
      postAdvancesSearchPlaces: api.place.postAdvancesSearchPlaces,
    },
  },
  placelists: {
    methods: {
      get: api.placelists.getPlacelistHistory,
    },
  },
  placelist: {
    methods: {
      get: api.placelists.getPlacelist,
    },
  },
  bugReport: {
    methods: {
      create: api.bugReport.postBugReport,
    },
  },
  place: {
    methods: {
      get: api.place.getApiPlaceId,
      getBookingData: api.place.getBookingData,
    },
  },
};

export default storeApi;
