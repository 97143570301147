import api from "../storeApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { chatbotFeedbackPostFeedbackAction } from "./action";

export const postFeedbackRequest = function* (
  action: ReturnType<typeof chatbotFeedbackPostFeedbackAction.request>
) {
  try {
    let response = yield call(() =>
      api.chatbotFeedback.methods.postFeedback(...action.payload)
    );
    yield put(
      chatbotFeedbackPostFeedbackAction.success(response.data, action.meta)
    );
  } catch (error) {
    yield put(chatbotFeedbackPostFeedbackAction.failure(error, action.meta));
  }
};

export const chatbotFeedbackSaga = function* () {
  yield all([
    takeEvery(chatbotFeedbackPostFeedbackAction.request, postFeedbackRequest),
  ]);
};
