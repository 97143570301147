import React, { useMemo } from "react";
import {
  StyleProp,
  ViewStyle,
  View,
  Text,
  StyleSheet,
  Pressable,
  Linking,
} from "react-native";
import { CheckTrue } from "@gadder/common/src/assets/icons/CheckTrue";
import { CheckFalse } from "@gadder/common/src/assets/icons/CheckFalse";
import { MascotSubscribeStarter } from "@gadder/common/src/assets/icons/mascot_subscribe_starter";
import { MascotSubscribeMedium } from "@gadder/common/src/assets/icons/mascot_subscribe_medium";
import { MascotSubscribePremium } from "@gadder/common/src/assets/icons/mascot_subscribe_premium";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { format, add } from "date-fns";

const stripeUserPortalUrl = process.env.REACT_APP_STRIPE_USER_PORTAL_URL;

export type FeatureType = {
  available: boolean;
  name: string;
};

export type SubscriptionType = {
  id: number;
  title: string;
  type: "start" | "medium" | "premium";
  price: string;
  features: Array<FeatureType>;
};

export function SubscriptionCard({
  subscription,
  containerStyle,
  onSubscribe,
  paymentData,
}: {
  subscription: SubscriptionType;
  containerStyle?: StyleProp<ViewStyle>;
  onSubscribe?: () => void;
  paymentData?: {
    is_expired: boolean;
    subscription_expire_at?: string;
    is_active?: boolean;
    stripe_user_id?: string;
  };
}) {
  console.log("paymentData", paymentData);

  const icon = {
    start: <MascotSubscribeStarter width={95} />,
    medium: <MascotSubscribeMedium width={95} />,
    premium: <MascotSubscribePremium width={95} />,
  };

  const openStripeUserPortal = () => {
    Linking.openURL(stripeUserPortalUrl);
  };

  const isExpired = paymentData.is_expired;
  const isActive = paymentData.is_active;
  const isPrevSubscription =
    !!paymentData.subscription_expire_at && !!paymentData.stripe_user_id;

  const buttonConfig = useMemo(() => {
    switch (subscription.type) {
      case "start":
        return isExpired
          ? { title: "Active", color: colors.black }
          : isActive
          ? {
              title: "Downgrade",
              color: "rgba(37, 31, 44, 0.8)",
              onPress: openStripeUserPortal,
            }
          : {
              title: "Starts after expiration date",
              color: "rgba(37, 31, 44, 0.8)",
            };
      case "premium":
        return isExpired
          ? isPrevSubscription
            ? {
                title: "Renew subscription",
                color: colors.blue1,
                onPress: openStripeUserPortal,
              }
            : { title: "Upgrade", color: colors.blue1, onPress: onSubscribe }
          : isActive
          ? {
              title: "Active",
              color: colors.black,
            }
          : {
              title: "Renew subscription",
              color: colors.blue1,
              onPress: openStripeUserPortal,
            };
    }
  }, [paymentData, subscription.type]);

  return (
    <View style={[styles.cardContainer, containerStyle]}>
      <Text
        style={[
          fonts.averageLargeText_bold,
          { color: colors.black, marginBottom: 16 },
        ]}
      >
        {subscription.title}
      </Text>

      <View style={styles.priceContainer}>
        <View style={{ width: 75, height: 80, marginRight: 24 }}>
          {icon[subscription.type]}
        </View>
        <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
          {subscription.price ? (
            <>
              <Text style={[fonts.huge_normal, { color: colors.black }]}>
                {subscription.price}
              </Text>
              <Text
                style={[fonts.mediumLargeText_regular, { color: colors.black }]}
              >
                {" "}
                /month
              </Text>
            </>
          ) : (
            <Text style={[fonts.largeText_semiBold, { color: colors.black }]}>
              Free
            </Text>
          )}
        </View>
      </View>

      {subscription.type !== "start" && paymentData.subscription_expire_at && (
        <Text
          style={{
            marginBottom: 16,
            color: colors.noteGray,
            ...fonts.mediumText_regular,
          }}
        >
          {(isActive ? "Auto renews on " : "Expires on ") +
            format(new Date(paymentData.subscription_expire_at), "dd.MM.Y")}
        </Text>
      )}

      <View style={styles.line} />
      <Text style={[fonts.mediumText_bold, styles.description]}>
        What’s inside?
      </Text>
      <View
        style={{
          alignItems: "flex-start",
          marginBottom: 16,
        }}
      >
        {subscription.features.map((feature) => (
          <View style={{ flexDirection: "row", marginBottom: 12 }}>
            <View style={{ width: 24, marginRight: 8 }}>
              {feature.available ? <CheckTrue /> : <CheckFalse />}
            </View>
            <Text
              style={[
                fonts.mediumText_regular,
                { color: feature.available ? colors.noteGray : colors.gray50 },
              ]}
            >
              {feature.name}
            </Text>
          </View>
        ))}
      </View>
      <CommonButton
        textStyle={fonts.mediumText_bold}
        title={buttonConfig.title}
        type="blackFilled"
        containerStyle={{
          minWidth: 200,
          backgroundColor: buttonConfig.color,
          borderWidth: 0,
        }}
        onPress={buttonConfig?.onPress}
      />

      {subscription.type === "premium" && isActive && (
        <Pressable onPress={openStripeUserPortal}>
          <Text
            style={{
              marginTop: 16,
              textDecorationLine: "underline",
              color: colors.noteGray,
              ...fonts.mediumText_regular,
            }}
          >
            Cancel subscription
          </Text>
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    paddingHorizontal: 36,
    paddingVertical: 24,
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 16,
    boxShadow: "0px 4px 12px 0px rgba(92, 107, 140, 0.30)",
    width: 350,
    marginBottom: 12,
  },
  priceContainer: {
    flexDirection: "row",
    marginBottom: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: colors.gray81,
    width: "100%",
    marginBottom: 16,
  },
  description: {
    color: colors.black,
    marginBottom: 12,
    textAlign: "left",
    alignSelf: "flex-start",
    fontWeight: "600",
  },
});
