import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  Pressable,
  Image,
  ImageBackground,
  Linking,
} from "react-native";
import { useIsMobile } from "./ChatPage";
import { MobileHeader } from "../components/MobileHeader";
import { ReactComponent as GadderLogo } from "@gadder/common/src/assets/svg/gadder_logo_big_full.svg";
import { ReactComponent as WhatsAppQrIcon } from "@gadder/common/src/assets/svg/whatsapp_qr_code.svg";
import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";
import { ReactComponent as WhatsAppIcon } from "@gadder/common/src/assets/svg/whatsapp_icon_2.svg";
import { TabButton } from "../components/TabButton";
import { ReactComponent as ChatBlackIcon } from "@gadder/common/src/assets/svg/chat_black.svg";
import { ReactComponent as ChatLightIcon } from "@gadder/common/src/assets/svg/chat_light.svg";
import { ReactComponent as MapBlackIcon } from "@gadder/common/src/assets/svg/map_tab_black.svg";
import { ReactComponent as MapWhiteIcon } from "@gadder/common/src/assets/svg/map_tab_light.svg";
import { ReactComponent as WhatsappIcon } from "@gadder/common/src/assets/svg/whatsapp_icon_3.svg";
import Background from "@gadder/common/src/assets/images/main_page_background.jpg";
import WhatsappUI from "@gadder/common/src/assets/images/main_page_wa_ui.png";
import BackgroundMobile from "@gadder/common/src/assets/images/main_page_background_mobile.jpg";
import { UserAvatar } from "@gadder/common/src/components/user-avatar";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../store/root-reducer";
import { DrawerWrapper } from "../components/drawerWrapper";
import { SideMenu } from "../components/chat/sideMenu";
import { localeUrlHandler } from "../components/MapPin";
import ReactPixel from "react-facebook-pixel";

export const whatsappButtonHandler = () => {
  ReactPixel.trackCustom("WhatsappChatbotButtonPressed", {});
  const whatsappUrl = "https://wa.me/message/FUCMIB64LMCSO1";
  Linking.openURL(whatsappUrl);
};

export const MainPage = () => {
  const isMobile = useIsMobile();
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((store: RootState) => store.user);
  return (
    <View style={[{ flex: 1 }, isMobile && { flexDirection: "column" }]}>
      {isMobile && (
        <MobileHeader onBurgerPress={() => setIsMobileDrawerOpen(true)} />
      )}

      {!isMobile && (
        <View style={styles.header}>
          <GadderLogo />

          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                flexDirection: "row",
                gap: 12,
                alignSelf: "center",
                marginRight: 24,
              }}
            >
              <TabButton
                textStyle={fonts.mediumText_bold}
                containerStyle={styles.tab}
                label={"Book"}
                isSelected={false}
                onPress={() => navigate("/book")}
              />

              <TabButton
                textStyle={fonts.mediumText_bold}
                containerStyle={styles.tab}
                label={"Chats"}
                isSelected={false}
                // Icon={ChatBlackIcon}
                // ActiveIcon={ChatLightIcon}
                onPress={() =>
                  user.data
                    ? navigate("/chat")
                    : navigate("/login-modal", {
                        state: { previousLocation: location },
                      })
                }
              />

              <TabButton
                containerStyle={styles.tab}
                textStyle={fonts.mediumText_bold}
                label={"Map"}
                isSelected={false}
                // Icon={MapBlackIcon}
                // ActiveIcon={MapWhiteIcon}
                onPress={() => navigate("/map")}
              />
            </View>

            <Pressable
              onPress={() =>
                user.data ? navigate("/profile") : navigate("/login")
              }
              style={{ alignItems: "center" }}
            >
              <UserAvatar size={52} user={user.data} />
              <Text style={{ ...fonts.mediumText_bold, color: colors.black }}>
                {user.data ? "Profile" : "Log in"}
              </Text>
            </Pressable>
          </View>
        </View>
      )}

      <View style={{ backgroundColor: "#f7f7f7", flex: 1 }}>
        <View
          style={[
            {
              flex: 1,
              marginHorizontal: 16,
              marginTop: 12,
              marginBottom: 8,
              overflow: "hidden",
              borderRadius: 12,
              borderWidth: 2,
              borderColor: "white",
            },
            isMobile && {
              marginHorizontal: 8,
              marginTop: 12,
              marginBottom: 8,
            },
          ]}
        >
          <ImageBackground
            source={isMobile ? BackgroundMobile : Background}
            style={{
              overflow: "hidden",
              flex: 1,
              borderRadius: 12,
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.15)",
            }}
          >
            {!isMobile && (
              <View
                style={{
                  position: "absolute",
                  right: -2,
                  top: "10%",
                  zIndex: 0,
                }}
              >
                <Image
                  style={{
                    width: 353 * 1.5,
                    height: 350 * 1.5,
                    resizeMode: "cover",
                    // position: "absolute",
                    // right: -2,
                    // top: 40,
                    // zIndex: 10,
                  }}
                  source={WhatsappUI}
                />
              </View>
            )}

            <View
              style={[
                {
                  alignSelf: "flex-start",
                  paddingHorizontal: 30,
                  paddingTop: 30,
                  paddingBottom: 50,
                  position: "relative",

                  marginTop: 55,
                  marginLeft: 70,
                  backgroundColor: "rgba(245, 245, 246, 0.9)",
                  borderRadius: 10,
                },
                isMobile && {
                  marginTop: 72,
                  marginLeft: 12,
                  padding: 0,
                  backgroundColor: "unset",
                },
              ]}
            >
              <Text
                style={[
                  {
                    ...fonts.huge_extraBold,
                    lineHeight: 40,
                    color: colors.black,
                    marginBottom: 24,
                  },
                  isMobile && {
                    ...fonts.largeText_extraBold,
                    fontSize: 32,
                    marginBottom: 12,
                  },
                ]}
              >
                {isMobile
                  ? "Change the way\nyou eat out"
                  : "Change the way you\neat out"}
              </Text>
              <Text
                style={[
                  {
                    ...fonts.largeText_regular,
                    color: colors.black,
                    marginBottom: 40,
                  },
                  isMobile && {
                    ...fonts.mediumText_regular,
                    fontSize: 20,
                    marginBottom: 12,
                  },
                ]}
              >
                Discover and book the best{"\n"}restaurants, right in WhatsApp
              </Text>

              <Pressable onPress={whatsappButtonHandler}>
                <View
                  style={[
                    {
                      alignSelf: "flex-start",
                      backgroundColor: "#00A001",
                      paddingHorizontal: 16,
                      paddingVertical: 21,
                      borderRadius: 32,
                      borderWidth: 2,
                      borderColor: "white",
                      alignItems: "center",
                      flexDirection: "row",
                    },
                    isMobile && {
                      paddingHorizontal: 16,
                      paddingVertical: 12,
                      borderRadius: 28,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        ...fonts.mediumText_bold,
                        color: colors.white,
                        paddingRight: 12,
                      },
                      isMobile && { ...fonts.smallMediumText_bold },
                    ]}
                  >
                    Message AI concierge
                  </Text>
                  <View style={[isMobile && { paddingTop: 2 }]}>
                    <WhatsappIcon
                      width={isMobile ? 32 : 36}
                      height={isMobile ? 30 : 36}
                    />
                  </View>
                </View>
              </Pressable>
            </View>
          </ImageBackground>
        </View>

        <View
          style={[
            { paddingBottom: 8, paddingHorizontal: 24 },
            isMobile && { paddingBottom: 12, paddingHorizontal: 16 },
          ]}
        >
          <View
            style={{
              alignItems: "center",
              gap: 36,
              flexDirection: "row",
              paddingLeft: 32,
            }}
          >
            <Text style={styles.footerText}>© Gadder</Text>
            <Text style={styles.footerText}>Privacy Policy</Text>
            <Text style={styles.footerText}>Contact: hello@gadderapp.com</Text>
          </View>
        </View>
      </View>

      {isMobile && (
        <DrawerWrapper
          onClose={() => setIsMobileDrawerOpen(false)}
          isOpen={isMobileDrawerOpen}
        >
          <SideMenu />
        </DrawerWrapper>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 8,
    paddingHorizontal: 58,
    paddingTop: 8,
    borderBottomWidth: 1,
    borderBottomColor: "rgba(0,0,0,0.3)",
  },
  tab: { paddingHorizontal: 18, paddingVertical: 8 },
  footerText: {
    ...fonts.little_medium,
    color: colors.black,
    textDecorationLine: "underline",
  },
});
