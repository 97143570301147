import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";

export const chatbotFeedbackPostFeedbackAction = createAsyncActionWithMeta(
  "chatbotFeedback_chatbotFeedbackPostFeedbackAction_REQUEST",
  "chatbotFeedback_chatbotFeedbackPostFeedbackAction_SUCCESS",
  "chatbotFeedback_chatbotFeedbackPostFeedbackAction_FAILURE"
)<
  Parameters<typeof api.chatbotFeedback.methods.postFeedback>,
  typeof api.chatbotFeedback.type,
  Error
>();
