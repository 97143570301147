import React, { ReactNode } from "react";

import { ReactComponent as MascotSubscribeStarter } from "@gadder/common/src/assets/svg/gadder_moscot.svg";
import {
  Pressable,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { ChatPage, useIsMobile } from "../pages/ChatPage";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonScreenCard } from "../components/CommonScreenCard";
import { ReactComponent as BackIcon } from "@gadder/common/src/assets/svg/arrow_left_black.svg";

export const LoginModal = () => {
  console.log("LoginModal???");

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const location = useLocation();

  const backRoute = () => navigate(location.state?.previousLocation || "/");

  return (
    <View style={{ height: "100vh", position: "relative" }}>
      <ChatPage modalMode />

      <ModalWrapper>
        <CommonScreenCard
          screenStyle={[
            {
              justifyContent: "center",
            },
            isMobile && { height: "100%", marginHorizontal: 48 },
          ]}
          style={[
            {
              backgroundColor: "white",
              zIndex: 6,
              height: "unset",
            },
            isMobile && {
              boxShadow: "0px 4px 12px 0px rgba(92, 107, 140, 0.30)",
            },
          ]}
        >
          <TouchableOpacity
            onPress={backRoute}
            style={{
              flexDirection: "row",
              alignItems: "center",
              alignSelf: "flex-start",
              paddingLeft: isMobile ? 16 : 32,
              paddingTop: isMobile ? 12 : 24,
            }}
          >
            <BackIcon style={{ marginRight: 4 }} />

            <Text style={[fonts.mediumText_regular, { color: colors.black }]}>
              back
            </Text>
          </TouchableOpacity>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: isMobile ? 24 : 30,
            }}
          >
            <MascotSubscribeStarter
              style={{ marginBottom: isMobile ? 0 : 12 }}
            />
            <Text
              style={[
                isMobile
                  ? fonts.mediumText_semiBold
                  : fonts.mediumLargeText_bold,
                {
                  marginBottom: isMobile ? 12 : 42,
                  color: colors.black,
                  textAlign: "center",
                  paddingHorizontal: isMobile ? 16 : 32,
                },
              ]}
            >
              Please register or log in into your account to use our chatbot,
              save your favourite places and get personalised recommendations.
            </Text>
            <View style={{ flexDirection: "column" }}>
              <CommonButton
                textStyle={fonts.mediumText_bold}
                title="Login"
                type="blackFilled"
                containerStyle={{ width: 170, marginBottom: 12 }}
                onPress={() =>
                  navigate("../phone", {
                    state: { redirectAfterLoginTo: "/chat" },
                  })
                }
              />
              <CommonButton
                textStyle={fonts.mediumText_bold}
                title="Registration"
                type="black"
                containerStyle={{ width: 170 }}
                onPress={() =>
                  navigate("../phone", {
                    state: { redirectAfterLoginTo: "/chat" },
                  })
                }
              />
            </View>
          </View>
        </CommonScreenCard>
      </ModalWrapper>
    </View>
  );
};

export const ModalWrapper = ({ children }: { children?: ReactNode }) => {
  const isMobile = useIsMobile();
  return (
    <View
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 10,
        backdropFilter: "blur(10px)",
        justifyContent: "center",
      }}
    >
      {children}
    </View>
  );
};
