import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { LandingSubscriptionPage } from "./LandingSubscriptionPage";
import { PhonePage } from "./PhonePage";
import { PlacePage } from "./PlacePage";
import { CodePage } from "./CodePage";
import { NamePage } from "./NamePage";
import { ProfilePage } from "./ProfilePage";
import { Provider as ReduxProvider } from "react-redux";
import store from "../store";

import useUniqueDispatch, {
  isLoading,
  MobileContext,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";

import { RootState } from "../store/root-reducer";
import { createContext, useContext, useEffect, useState } from "react";
import { userGetMeAction } from "../store/user/action";
import { ActivityIndicator, Dimensions, View } from "react-native";
import { SendEmailPage } from "./SendEmailPage";
import { ChatPage } from "./ChatPage";
import { MapPageOld } from "./MapPageOld";
import { colors } from "@gadder/common/src/styles/colors";
import { LoginModal } from "./LoginModal";
import { MapPage } from "./MapPage";
import { BookPage } from "./BookPage";
import { BugReportModal } from "./BugReportModal";
import { SubscriptionSuccess } from "./SubscriptionSuccess";
import { MainPage } from "./MainPage";
import { ChatbotFeedbackModal } from "./ChatbotFeedbackModal";
import ReactPixel from "react-facebook-pixel";

export const CommonContext = createContext<{
  redirectAfterLoginTo: string | null;
  setRedirectAfterLoginTo: (v: string | null) => void;
}>({
  redirectAfterLoginTo: null,
  setRedirectAfterLoginTo: () => {},
});

const Navigation = () => {
  const token = useSelector((store: RootState) => store.token.data);

  const userRequest = useSelector((store: RootState) => store.user);

  const [getMeToken, getMe] = useUniqueDispatch(userGetMeAction.request);

  const isUserLoading = isLoading(userRequest, getMeToken);

  useEffect(() => {
    if (token && !userRequest.data) {
      getMe([]);
    }
  }, [token]);

  const [redirectAfterLoginTo, setRedirectAfterLoginTo] = useState<
    string | null
  >(null);

  return (
    <CommonContext.Provider
      value={{ redirectAfterLoginTo, setRedirectAfterLoginTo }}
    >
      <View style={{ height: Dimensions.get("window").height }}>
        {isUserLoading ? (
          <ActivityIndicator color={colors.black} style={{ flex: 1 }} />
        ) : (
          <MainNavigation />
        )}
      </View>
    </CommonContext.Provider>
  );
};

export const AuthContext = createContext({
  phone: "",
  setPhone: (v: string) => {},
});

export const ChatContext = createContext<{
  lastOpenedChatId: number | null;
  setLastOpenedChatId: (val: number | null) => void;
}>({
  lastOpenedChatId: null,
  setLastOpenedChatId: () => {},
});

const MainNavigation = () => {
  const user = useSelector((store: RootState) => store.user.data);

  console.log("user", user);

  const [phone, setPhone] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.first_name || !user.second_name) {
        navigate("/name");
        return;
      }
    }
  }, [user]);

  const { redirectAfterLoginTo, setRedirectAfterLoginTo } =
    useContext(CommonContext);

  useEffect(() => {
    if (redirectAfterLoginTo && user) {
      navigate(redirectAfterLoginTo);
      setRedirectAfterLoginTo(null);
    }
  }, [redirectAfterLoginTo, user]);

  const [lastOpenedChatId, setLastOpenedChatId] = useState<number | null>(null);

  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  return (
    <AuthContext.Provider value={{ phone, setPhone }}>
      <ChatContext.Provider value={{ lastOpenedChatId, setLastOpenedChatId }}>
        <Routes location={previousLocation || location}>
          {user?.id ? (
            <>
              <Route path={"/chat"} element={<ChatPage />} />
              <Route path={"/profile"} element={<ProfilePage />} />
              <Route path={"/name"} element={<NamePage />} />
              <Route
                path={"/subscription-success"}
                element={<Navigate replace to={"/profile"} />}
              />
            </>
          ) : (
            <>
              <Route path={"/login"} element={<LandingSubscriptionPage />} />
              <Route path={"/phone"} element={<PhonePage />} />
              <Route path={"/code"} element={<CodePage />} />
              <Route path={"/chat"} element={<LoginModal />} />
              <Route path={"/profile"} element={<LandingSubscriptionPage />} />
              <Route
                path={"/subscription-success"}
                element={<SubscriptionSuccess />}
              />
            </>
          )}

          <Route path={"/"} element={<MainPage />} />
          <Route path={"/book"} element={<BookPage />} />
          <Route path={"/map"} element={<MapPage />} />
          {/*<Route path={"/placelists/:id?"} element={<PlacelistaPage />} />*/}
          <Route path={"/place/:placeId"} element={<PlacePage />} />

          <Route path="/chatbot-feedback" element={<ChatbotFeedbackModal />} />

          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>

        <Routes>
          {previousLocation && (
            <>
              <Route path="/bug-report" element={<BugReportModal />} />
              {!user?.id && (
                <Route path={"/login-modal"} element={<LoginModal />} />
              )}
            </>
          )}
        </Routes>
      </ChatContext.Provider>
    </AuthContext.Provider>
  );
};

export const SubscriptionWrapper = () => {
  return (
    <ReduxProvider context={MobileContext} store={store}>
      <Navigation />
    </ReduxProvider>
  );
};
