import React, { useEffect, useState } from "react";
import {
  Linking,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
  Text,
} from "react-native";
import { ReactComponent as WhatsappIcon } from "@gadder/common/src/assets/svg/whatsapp_icon_3.svg";
import { ReactComponent as GadderIcon } from "@gadder/common/src/assets/svg/gadder_mascot_black.svg";
import { ReactComponent as CrossIcon } from "@gadder/common/src/assets/svg/cross_grey.svg";
import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";
import { getWhatsAppPopupState, setWhatsAppPopupState } from "../api/storage";
import { useIsMobile } from "../pages/ChatPage";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { whatsappButtonHandler } from "../pages/MainPage";

export const WhatsappButton = ({ style }: { style?: StyleProp<ViewStyle> }) => {
  const [isPopupShown, setIsPopupShown] = useState(false);

  const closeHandler = () => {
    setIsPopupShown(false);
  };

  useEffect(() => {
    getWhatsAppPopupState().then((state) => {
      const showPopUp =
        !state ||
        differenceInMinutes(new Date(), new Date(state.dateClosed)) >= 10;

      if (showPopUp) {
        setIsPopupShown(true);
        setWhatsAppPopupState({ dateClosed: new Date() });
      }
    });
  }, []);

  const isMobile = useIsMobile();

  return (
    <View style={style}>
      <Pressable
        onPress={whatsappButtonHandler}
        style={{
          width: 65,
          height: 65,
          borderRadius: 65,
          backgroundColor: colors.greenBright,
          borderWidth: 1,
          borderColor: colors.white,
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <View style={{ paddingRight: 4 }}>
          <WhatsappIcon width={45} height={35} />
        </View>
      </Pressable>
      {isPopupShown && (
        <View
          style={{
            position: "absolute",
            bottom: "calc(100% + 5px)",
            right: "50%",
          }}
        >
          <View
            style={{
              width: isMobile ? 192 : 240,
              backgroundColor: "#F4F4F6",
              paddingHorizontal: 16,
              paddingVertical: 20,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.5)",
            }}
          >
            <Text
              style={[
                { color: colors.black },
                isMobile
                  ? fonts.smallText_medium
                  : fonts.smallMediumText_medium,
              ]}
            >
              Hi, I am Gadder,{"\n"}your WhatsApp AI Concierge.{"\n\n"}Press
              here{"\n"}to book with me!
            </Text>

            <Pressable
              onPress={closeHandler}
              style={{ position: "absolute", right: 10, top: 10 }}
            >
              <CrossIcon width={20} height={20} />
            </Pressable>

            <View style={{ position: "absolute", right: 10, bottom: 4 }}>
              <GadderIcon width={30} height={36} />
            </View>
          </View>
        </View>
      )}
    </View>
  );
};
