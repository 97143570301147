import api from "../storeApi";
import { createAsyncActionWithMeta } from "../../../../../libraries/packages/redux-utils/src/request/action";

export const chatbotUserDataGetChatbotUserDataAction =
  createAsyncActionWithMeta(
    "chatbotUserData_chatbotUserDataGetChatbotUserDataAction_REQUEST",
    "chatbotUserData_chatbotUserDataGetChatbotUserDataAction_SUCCESS",
    "chatbotUserData_chatbotUserDataGetChatbotUserDataAction_FAILURE"
  )<
    Parameters<typeof api.chatbotUserData.methods.getChatbotUserData>,
    typeof api.chatbotUserData.type,
    Error
  >();
