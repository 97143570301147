import {
  View,
  Text,
  FlatList,
  Pressable,
  TextInput,
  ScrollView,
  StyleSheet,
} from "react-native";
import { ModalWrapper } from "./LoginModal";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { ReactComponent as CrossIcon } from "@gadder/common/src/assets/svg/cross_black.svg";
import { ReactComponent as GadderIcon } from "@gadder/common/src/assets/svg/gadder_mascot_black_big.svg";
import { useEffect, useState } from "react";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMobile } from "./ChatPage";
import useUniqueDispatch, {
  useSelector,
  isError,
  isData,
  isLoading,
} from "@gadder/common/src/utils/redux-utils";
import { bugReportCreateAction } from "../store/bugReport/action";
import { RootState } from "../store/root-reducer";
import { ReactComponent as StartIcon } from "@gadder/common/src/assets/svg/star.svg";
import { ReactComponent as StartActiveIcon } from "@gadder/common/src/assets/svg/star_active.svg";
import { chatbotFeedbackPostFeedbackAction } from "../store/chatbotFeedback/action";

enum ChatbotUnderstandingItems {
  ALWAYS = "always",
  MOST_TIME = "most_time",
  SOMETIMES = "sometimes",
  NEVER = "never",
}

enum ChatbotRecommendationBookingItems {
  ALWAYS = "always",
  MOST_TIME = "most_time",
  SOMETIMES = "sometimes",
  NEVER = "never",
}

export const ChatbotFeedbackModal = () => {
  const botUnderstandingButtons = [
    {
      type: ChatbotUnderstandingItems.ALWAYS,
      label: "Always",
    },
    {
      type: ChatbotUnderstandingItems.MOST_TIME,
      label: "Most of the\ntime",
    },
    {
      type: ChatbotUnderstandingItems.SOMETIMES,
      label: "Sometimes",
    },
    {
      type: ChatbotUnderstandingItems.NEVER,
      label: "Never",
    },
  ];

  const botRecommendationButtons = [
    {
      type: ChatbotRecommendationBookingItems.ALWAYS,
      label: "Always",
    },
    {
      type: ChatbotRecommendationBookingItems.MOST_TIME,
      label: "Most of the\ntimes",
    },
    {
      type: ChatbotRecommendationBookingItems.SOMETIMES,
      label: "Sometimes",
    },
    {
      type: ChatbotRecommendationBookingItems.NEVER,
      label: "Never",
    },
  ];

  const [searchParams] = useSearchParams();

  const [feedbackUuid, setFeedbackUuid] = useState<string>();

  console.log("feedbackUuid", feedbackUuid);

  useEffect(() => {
    const uuid = searchParams.get("uuid");
    if (uuid) {
      setFeedbackUuid(uuid);
    } else {
      navigate("/");
    }
  }, [searchParams]);

  const [comment, setComment] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [submitToken, submit] = useUniqueDispatch(
    chatbotFeedbackPostFeedbackAction.request
  );

  const bugReportRequest = useSelector(
    (store: RootState) => store.chatbotFeedback
  );

  const isSubmitting = isLoading(bugReportRequest, submitToken);
  const isRequestError = isError(bugReportRequest, submitToken);
  const isSubmitted = isData(bugReportRequest, submitToken);

  console.log("isRequestError", isRequestError);

  const closeHandler = () => navigate(location?.state?.previousLocation || "/");

  const [rating, setRating] = useState(0);

  const [selectedUnderstandingOption, setSelectedUnderstandingOption] =
    useState<null | ChatbotUnderstandingItems>(null);

  const [selectedRecommendationOption, setSelectedRecommendationOption] =
    useState<null | ChatbotRecommendationBookingItems>(null);

  const submitHandler = () => {
    submit([
      {
        uuid: feedbackUuid,
        rating: rating,
        comment: comment,
        bot_understanding: selectedUnderstandingOption,
        bot_recommendation: selectedRecommendationOption,
      },
    ]);
  };

  const optionsSelected =
    feedbackUuid &&
    !!selectedUnderstandingOption &&
    !!selectedRecommendationOption &&
    !!rating;

  return (
    <ModalWrapper>
      <View
        style={{
          width: isMobile ? "100%" : 600,
          maxHeight: isMobile ? 800 : "unset",
          backgroundColor: colors.white,
          borderRadius: 16,
          alignSelf: isMobile ? "unset" : "center",
          alignItems: "center",

          shadowOpacity: 0.3,
          shadowRadius: 16,
          shadowOffset: {
            width: 0,
            height: 0,
          },
          shadowColor: "#000000",
        }}
      >
        <ScrollView
          contentContainerStyle={{
            paddingTop: 50,
            paddingBottom: 64,
            paddingHorizontal: 16,
          }}
        >
          <Pressable onPress={closeHandler} style={{ alignSelf: "flex-end" }}>
            <CrossIcon />
          </Pressable>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 64,
              paddingHorizontal: isMobile ? 0 : 32,
            }}
          >
            <View style={{ width: 45 }}>
              <GadderIcon width={45} height={65} />
            </View>
            <Text
              style={{
                color: colors.black,
                ...fonts.giant_bold,
                marginLeft: 23,
              }}
            >
              Please tell us about your experience
            </Text>
          </View>

          {isSubmitted ? (
            <Text
              style={{
                textAlign: "center",
                color: colors.black,
                ...fonts.mediumLargeText_500,
                marginBottom: 64,
              }}
            >
              Thank You!{"\n\n"}Your feedback matters!
            </Text>
          ) : (
            <>
              <View
                style={[
                  styles.commonContainer,
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 24,
                  },
                ]}
              >
                <View style={{ flex: 1, minWidth: 80 }}>
                  <Text
                    style={{
                      color: colors.black,
                      ...fonts.mediumLargeText_bold,
                    }}
                  >
                    Overall:
                  </Text>
                </View>
                <FlatList
                  horizontal={true}
                  data={Array(5)}
                  renderItem={({ item, index }) => (
                    <Pressable onPress={() => setRating(index + 1)}>
                      {index < rating ? (
                        <StartActiveIcon width={42} height={42} />
                      ) : (
                        <StartIcon width={42} height={42} />
                      )}
                    </Pressable>
                  )}
                />
              </View>

              <View style={[styles.commonContainer, { marginBottom: 12 }]}>
                <Text
                  style={{
                    color: colors.black,
                    ...fonts.mediumLargeText_bold,
                    marginBottom: 16,
                  }}
                >
                  Gadder bot understands my requests correctly:
                </Text>

                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-around",
                    flexDirection: "row",
                  }}
                >
                  {botUnderstandingButtons.map((item) => (
                    <Button
                      onPress={() =>
                        setSelectedUnderstandingOption(
                          item.type === selectedUnderstandingOption
                            ? null
                            : item.type
                        )
                      }
                      isActive={item.type === selectedUnderstandingOption}
                      label={item.label}
                    />
                  ))}
                </View>
              </View>

              <View style={[styles.commonContainer, { marginBottom: 16 }]}>
                <Text
                  style={{
                    color: colors.black,
                    ...fonts.mediumLargeText_bold,
                    marginBottom: 16,
                  }}
                >
                  I get good restaurant recommendations for bookings:
                </Text>

                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-around",
                    flexDirection: "row",
                  }}
                >
                  {botRecommendationButtons.map((item) => (
                    <Button
                      onPress={() =>
                        setSelectedRecommendationOption(
                          item.type === selectedRecommendationOption
                            ? null
                            : item.type
                        )
                      }
                      isActive={item.type === selectedRecommendationOption}
                      label={item.label}
                    />
                  ))}
                </View>
              </View>

              <View style={styles.commonContainer}>
                <Text
                  style={{
                    color: colors.black,
                    ...fonts.mediumLargeText_bold,
                  }}
                >
                  Let us know what you think about it and what you would like to
                  see:
                </Text>
              </View>

              <TextInput
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  color: colors.black,
                  ...fonts.mediumText_medium,
                  height: 90,
                  outline: "none",
                  width: "100%",
                  ...styles.commonContainer,
                }}
                placeholderTextColor={colors.darkGray}
                multiline
                placeholder={"Comment:"}
                onChangeText={setComment}
                value={comment}
              />
            </>
          )}

          <CommonButton
            disabled={!optionsSelected}
            style={{ alignSelf: "center" }}
            containerStyle={{
              paddingHorizontal: 66,
              paddingVertical: 10,
              backgroundColor: colors.newBlue,
              borderWidth: 0,
              borderRadius: 28,
            }}
            onPress={isSubmitted ? closeHandler : submitHandler}
            isLoading={isSubmitting}
            textStyle={{ color: colors.white, ...fonts.mediumLargeText_bold }}
            title={isSubmitted ? "Close" : "Submit"}
          />
        </ScrollView>
      </View>
    </ModalWrapper>
  );
};

const Button = ({
  label,
  isActive,
  onPress,
}: {
  label: string;
  isActive: boolean;
  onPress: () => void;
}) => {
  const isMobile = useIsMobile();
  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.button,
        isMobile && { width: 76 },
        isActive && styles.activeButton,
      ]}
    >
      <Text
        style={[
          styles.buttonText,
          isActive && styles.activeButtonText,
          isMobile && { ...fonts.little_semibold },
        ]}
      >
        {label}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  commonContainer: {
    borderColor: colors.noteGray,
    borderWidth: 2,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 10,
  },
  button: {
    width: 86,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
    borderRadius: 10,
    borderColor: colors.noteGray,
  },
  buttonText: {
    textAlign: "center",
    color: colors.black,
    ...fonts.smallText_semiBld,
  },
  activeButton: {
    borderWidth: 0,
    backgroundColor: colors.black,
  },
  activeButtonText: {
    color: colors.white,
  },
});
