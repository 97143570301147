import React, { ReactNode } from "react";
import { SideMenu, SideMenuTabs } from "../chat/sideMenu";
import {
  ActivityIndicator,
  FlatList,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { CommonInput } from "../inputs";

import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";
import { PlaceCardMedium } from "@gadder/common/src/components/PlaceCardMedium";
import { useIsMobile } from "../../pages/ChatPage";
import { FullPlaceType } from "@gadder/common/src/api/custom-models/place";
import { ReactComponent as FilterIcon } from "@gadder/common/src/assets/svg/filter_new_light.svg";
import { ReactComponent as FilterBlackIcon } from "@gadder/common/src/assets/svg/filter_new_black.svg";

import { ReactComponent as SearchBlack } from "@gadder/common/src/assets/svg/search_map.svg";
import { DatePicker } from "../DatePicker";
import { PlaceSearchFiltersType } from "@gadder/common/src/components/placeSearch/types/filters";
import { InputSelect } from "../InputSelect";
import { CommonButton } from "@gadder/common/src/components/buttons";
import { DotsLoading } from "../DotsLoading";

export const MapSidePanel = ({
  onSearchValueChange,
  onFilterPress,
  onSearchPress,
  searchValue,
  selectedFilters,
  isFiltersSelected,
  onFilterChange,
  ...props
}: {
  onPlaceSelect?: (place: FullPlaceType | null) => void;
  onSearchPress?: () => void;
  onFilterPress?: () => void;
  places: FullPlaceType[];
  isLoading?: boolean;
  onLoadNext?: () => void;
  isNextLoading?: boolean;
  searchValue?: string;
  onSearchValueChange: (text: string) => void;
  isFiltersSelected?: boolean;
  selectedFilters: PlaceSearchFiltersType;
  onFilterChange?: React.Dispatch<React.SetStateAction<PlaceSearchFiltersType>>;
  showSearchButton?: boolean;
  onSearchButtonPressed?: () => void;
  bookingMode?: boolean;
}) => {
  return (
    <SideMenu selectedTab={SideMenuTabs.MAP}>
      <View style={styles.container}>
        <View style={styles.topSearchBar}>
          <View style={styles.searchBar}>
            <CommonInput
              textInputStyle={styles.input}
              style={styles.inputContainer}
              placeholder={"Search by name, cuisine, area or vibe..."}
              returnKeyType={"search"}
              value={searchValue}
              onChangeText={onSearchValueChange}
              onKeyPress={(event) => {
                if (
                  (event.nativeEvent.key === "Enter" ||
                    event.nativeEvent.key === "NumpadEnter") &&
                  searchValue
                ) {
                  event.preventDefault();
                  onSearchPress();
                }
              }}
            />
            <TouchableOpacity
              onPress={onSearchPress}
              style={[styles.searchButton]}
            >
              <View style={{ height: 22 }}>
                <SearchBlack />
              </View>
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            onPress={onFilterPress}
            style={[
              styles.filterButton,
              isFiltersSelected && {
                backgroundColor: colors.black,
              },
            ]}
          >
            <View style={{ height: 18 }}>
              {isFiltersSelected ? <FilterIcon /> : <FilterBlackIcon />}
            </View>
            <Text
              style={[
                fonts.smallText_medium,
                { color: isFiltersSelected ? colors.white : colors.black },
              ]}
            >
              Filter
            </Text>
          </TouchableOpacity>
        </View>

        <View
          style={{
            marginHorizontal: 32,
            marginTop: 8,
            flexDirection: "row",
          }}
        >
          <View style={{ flexDirection: "row", flex: 1 }}>
            <InputSelect
              value={selectedFilters.bookingFilters?.personsCount}
              // isActive={selectedFilters.bookingMode}
              style={{ marginRight: 8 }}
              onChange={(_count) =>
                onFilterChange((prevState) => ({
                  ...prevState,
                  bookingFilters: {
                    ...prevState.bookingFilters,
                    personsCount: _count,
                  },
                }))
              }
            />

            <DatePicker
              style={{ flex: 1 }}
              // isActive={selectedFilters.bookingMode}
              date={selectedFilters.bookingFilters?.date}
              calendarStyle={{ right: -200 }}
              setDate={(_date) =>
                onFilterChange((prevState) => ({
                  ...prevState,
                  bookingFilters: {
                    ...prevState.bookingFilters,
                    date: _date,
                  },
                }))
              }
            />
          </View>

          <View style={styles.toggleContainer}>
            <Pressable
              onPress={() =>
                onFilterChange((prevState) => ({
                  ...prevState,
                  bookingMode: !prevState.bookingMode,
                }))
              }
              style={[
                styles.bookableToggle,
                selectedFilters.bookingMode && styles.bookableToggleActive,
              ]}
            >
              Show availabilities
            </Pressable>
          </View>
        </View>

        <SearchPlaceList
          bookingDataParams={
            !selectedFilters.bookingMode
              ? selectedFilters.bookingFilters
              : undefined
          }
          contentContainerStyle={{
            paddingHorizontal: 32,
            gap: 16,
            paddingTop: 16,
            paddingBottom: 24,
            zIndex: -1,
          }}
          {...props}
        />
      </View>
    </SideMenu>
  );
};

export const SearchPlaceList = ({
  contentContainerStyle,
  places,
  isLoading,
  onLoadNext,
  isNextLoading,
  onPlaceSelect,
  onSearchButtonPressed,
  showSearchButton = false,
  bookingDataParams,
  onAddressPress,
  ListHeaderComponent,
  bookingMode = false,
}: {
  contentContainerStyle?: StyleProp<ViewStyle>;
  places: FullPlaceType[];
  isLoading?: boolean;
  onLoadNext?: () => void;
  isNextLoading?: boolean;
  onPlaceSelect?: (place: FullPlaceType | null) => void;
  showSearchButton?: boolean;
  onSearchButtonPressed?: () => void;
  bookingDataParams?: { date: Date; personsCount: string };
  onAddressPress?: (place: FullPlaceType | null) => void;
  ListHeaderComponent?: ReactNode;
  bookingMode?: boolean;
}) => {
  const isMobile = useIsMobile();
  const placeRoute = (place_id: string, booking_dates: string[] = []) => {
    window.open("/place/" + place_id, "_blank");
  };

  return (
    <View style={{ flex: 1, zIndex: -1 }}>
      <FlatList
        onEndReachedThreshold={0.1}
        contentContainerStyle={[{ flex: 1 }, contentContainerStyle]}
        renderItem={({ item, index }) =>
          item.name && (
            <PlaceCardMedium
              bookingDataParams={bookingDataParams}
              wide={!isMobile}
              onAddressPress={onAddressPress}
              onPress={() => onPlaceSelect(item)}
              onArrowPress={() => {
                placeRoute(item.place_id, item.booking_dates);
              }}
              style={index === places.length - 1 && { paddingBottom: 32 }}
              place={item}
            />
          )
        }
        ListHeaderComponentStyle={{ zIndex: 1 }}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponentStyle={{ flex: 1 }}
        ListFooterComponent={
          <View style={{ flex: 1 }}>
            {(isNextLoading || isLoading) &&
              (bookingMode ? (
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text
                    style={{
                      color: colors.black,
                      ...fonts.mediumText_semiBold,
                      textAlign: "center",
                    }}
                  >
                    Finding a table for you
                    <View style={{ width: 20 }}>
                      <DotsLoading />
                    </View>
                  </Text>
                </View>
              ) : (
                <ActivityIndicator
                  color={colors.black}
                  style={{ flex: 1, paddingBottom: 24 }}
                />
              ))}

            {places.length === 0 &&
              !isLoading &&
              (showSearchButton ? (
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <CommonButton
                    onPress={onSearchButtonPressed}
                    type={"black"}
                    size={"medium"}
                    containerStyle={{
                      paddingHorizontal: 16,
                      paddingVertical: 8,
                    }}
                    textStyle={fonts.mediumLargeText_medium}
                    title={"Find places"}
                  />
                </View>
              ) : (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Text style={styles.noMatch}>No match</Text>
                </View>
              ))}
          </View>
        }
        onEndReached={onLoadNext}
        data={isLoading ? [] : places}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  topSearchBar: {
    paddingTop: 12,
    paddingHorizontal: 32,
    flexDirection: "row",
  },
  container: {
    flex: 1,
    backgroundColor: colors.gray_f9,
  },
  inputContainer: {
    backgroundColor: colors.white,
    borderWidth: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    flex: 1,
  },
  input: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    ...fonts.smallMediumText_regular,
  },
  noMatch: {
    ...fonts.mediumText_bold,
    color: colors.black,
    textAlign: "center",
  },
  filterButton: {
    paddingHorizontal: 8,
    paddingTop: 4,
    paddingBottom: 0,

    backgroundColor: colors.white,

    borderWidth: 1,
    borderColor: colors.gray81,

    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  searchButton: {
    paddingHorizontal: 8,
    justifyContent: "center",
    borderColor: colors.gray81,
    backgroundColor: colors.white,
    borderLeftWidth: 1,
    height: "100%",
  },

  searchBar: {
    flexDirection: "row",
    marginRight: 8,
    borderWidth: 1,
    borderColor: colors.gray81,
    borderRadius: 10,
    overflow: "hidden",
    flex: 1,
  },
  toggleContainer: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: colors.gray81,
    borderRadius: 10,
    overflow: "hidden",
    // marginLeft: 32,
    marginLeft: 8,
    alignSelf: "flex-start",
  },
  bookableToggle: {
    width: 145,
    paddingVertical: 7,
    backgroundColor: colors.white,
    color: colors.black,
    textAlign: "center",
    ...fonts.smallMediumText_regular,
  },
  bookableToggleActive: {
    backgroundColor: colors.black,
    color: colors.white,
    ...fonts.smallMediumText_bold,
  },
});
