import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import createLoadingReducer from "../../../../../libraries/packages/redux-utils/src/request/create-loading-reducer";
import createOperationReducer from "../../../../../libraries/packages/redux-utils/src/request/create-operation-reducer";
import createErrorReducer from "../../../../../libraries/packages/redux-utils/src/request/create-error-reducer";
import api from "../storeApi";
import { chatbotUserDataGetChatbotUserDataAction } from "./action";

export const chatbotUserDataReducer = combineReducers<{
  data: typeof api.chatbotUserData.type;
  lastOpId: string | null;
  isLoading: Set<string>;
  error: Error;
}>({
  data: createReducer(null).handleAction(
    [chatbotUserDataGetChatbotUserDataAction.success],
    (state, action) => action.payload
  ),
  isLoading: createLoadingReducer(chatbotUserDataGetChatbotUserDataAction),
  lastOpId: createOperationReducer(chatbotUserDataGetChatbotUserDataAction),
  error: createErrorReducer(chatbotUserDataGetChatbotUserDataAction),
});
